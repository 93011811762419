import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Builders from '@sections/Builders';
import Contact from '@sections/Contact';
import Media from '@sections/Media';
import Photos from '@sections/Photos';
import Footer from '@sections/Footer';
import CookieConsent from "react-cookie-consent";


const IndexPage = () => (
  
  <Layout>
    <Navbar />
    <Header />
    <About />
    <Builders />
    <Media />
    <Photos />
    <Contact />
    <Footer />
  <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>

  </Layout>
);

export default IndexPage;
