import React from 'react';
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'
import styled from 'styled-components';
import NPDodgeLogo from '@static/NPD-Midnight.png';
import ExternalLink from '@common/ExternalLink';

import { Section, Container } from '@components/global';

const BRAND = [
  {
    logo: NPDodgeLogo,
    link: 'https://www.npdodge.com/councilbluffsiowa-real-estate',
  },
];

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const RECAPTCHA_KEY = "6LeNSM8ZAAAAAOxqhRlyBYdwkbW9LlRFw8EZV6lI"
if (typeof RECAPTCHA_KEY === 'undefined') {
  
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = () => {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (   
    <Section id="contact" accent="secondary">
      <ContactWrapper>
        <Container>
            <h1>Contact Us</h1>
            <p>For lot reservations, more info, lot pricing, etc.</p>
          <Grid>
            <Left>
              <Logo>Whispering Oaks 
                {BRAND.map(({ logo, link }) => (
                  <ExternalLink key={link} href={link}>
                    <img src={logo} width="140px" alt="NP Dodge" />
                  </ExternalLink>
                ))}
              </Logo>
              <p>1032 Woodbury Avenue</p>
              <p>Council Bluffs, IA 51503</p>
              <p>Phone: <a href="tel:+17123285008">712.328.5008</a></p>
              <p>Fax: 712.328.9395</p>
              <p>whisperingoakscb@npdodge.com</p>
            </Left>    
            <Right>
              <form
                name="wo"
                method="post"
                action="https://formspree.io/xeqpqpwa"
              >
                <p>
                  <label>
                    Your name:
                    <br />
                    <input type="text" name="name" onChange={handleChange} />
                  </label>
                </p>
                <p>
                  <label>
                    Your email:
                    <br />
                    <input type="email" name="_replyto" onChange={handleChange} />
                  </label>
                </p>
                <p>
                  <label>
                    Message:
                    <br />
                    <textarea name="message" onChange={handleChange} />
                  </label>
                </p>
                <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
                <Button>
                  <button type="submit" >Submit</button>
                </Button>
              </form>
            </Right>
          </Grid>
        </Container>
      </ContactWrapper>
    </Section>  
  )  
};

const Button = styled.div`
  background-color: #ABA8AF;
  border: none;
  border-radius: 8px;
  padding: 9px 32px;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 128px;
  text-align: left;
  align-items: center;
  justify-items: left;
  margin: 32px 0;
  
  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 1fr 1fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;


const Left = styled.p`
  ${props => props.theme.font_size.larger};
  color: ${props => props.theme.color.black.regular};
`;

const Right = styled.p`
  display: block;
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
`;

const ContactWrapper = styled.div`
  padding-top: 96px;
  background-color: ${props => props.theme.color.primary};
  padding-bottom: 128px;
`;

export default Contact;
